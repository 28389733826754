import { Title, ToastContext } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useContext } from "react";
import { useLocation } from "wouter";

import FormulaForm, { ValidatedType } from "src/components/FormulaForm";
import { Brand, Form, Species, FlowerGrade, GmpGacp } from "src/models";
import { useCreateFormulaMutation } from "src/queries";

const CreateFormula = () => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { mutateAsync: create } = useCreateFormulaMutation();

  const onSubmit = async (data: ValidatedType) => {
    try {
      await create({
        appearance:
          data.appearance.trim() !== "" ? data.appearance.trim() : null,
        aroma: data.aroma.trim() !== "" ? data.aroma.trim() : null,
        brand: data.brand as Brand,
        cbd: data.cbd !== null ? data.cbd.dividedBy(100) : null,
        cbg: data.cbg !== null ? data.cbg.dividedBy(100) : null,
        cbn: data.cbn !== null ? data.cbn.dividedBy(100) : null,
        controlled: data.controlled,
        cultivator:
          data.cultivator.trim() !== "" ? data.cultivator.trim() : null,
        flowerGrade:
          data.form === "FLOWER" ? (data.flowerGrade as FlowerGrade) : null,
        form: data.form as Form,
        gmpGacp: data.gmpGacp as GmpGacp,
        ingredients: data.ingredients,
        intendedUse:
          data.intendedUse.trim() !== "" ? data.intendedUse.trim() : null,
        irradiationType:
          data.irradiationType.trim() !== ""
            ? data.irradiationType.trim()
            : null,
        manufacturer:
          data.manufacturer.trim() !== "" ? data.manufacturer.trim() : null,
        market: data.market,
        nameInternal: data.nameInternal,
        parentStrain:
          data.parentStrain.trim() !== "" ? data.parentStrain.trim() : null,
        registeredName:
          data.registeredName.trim() !== "" ? data.registeredName.trim() : null,
        sku: data.sku.trim() !== "" ? data.sku.trim() : null,
        species: data.species === "" ? null : (data.species as Species),
        strain: data.strain,
        terpenes: data.terpenes,
        thc: data.thc !== null ? data.thc.dividedBy(100) : null,
      });
      addToast("Formula added", "success");
      setLocation("/formulas/");
    } catch {
      addToast("An unexpected error occurred. Please try again.", "error");
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: "/formulas/",
            label: "Formulas",
          },
          { label: "Add formula" },
        ]}
        title="Add formula"
      />
      <Card>
        <FormulaForm
          defaultValues={{
            appearance: "",
            aroma: "",
            brand: Brand.CURALEAF,
            cbd: null,
            cbg: null,
            cbn: null,
            cultivator: "",
            controlled: "true",
            flowerGrade: FlowerGrade.STANDARD,
            form: Form.FLOWER,
            gmpGacp: GmpGacp.GMP,
            ingredients: [],
            intendedUse: "",
            irradiationType: "",
            manufacturer: "",
            market: "gbr",
            nameInternal: "",
            parentStrain: "",
            registeredName: "",
            sku: "",
            species: Species.INDICA,
            strain: "",
            terpenes: [],
            thc: null,
          }}
          label="Add"
          onSubmit={onSubmit}
        />
      </Card>
    </>
  );
};

export default CreateFormula;
