import {
  SubmitButton,
  TextField,
  ToastContext,
  FormLinks,
  Title,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import {
  useApproveFormulaHistoryMutation,
  useFormulaHistoryQuery,
} from "src/queries";

interface IProps {
  formulaHistoryId: string;
}

const FormSchema = z.object({
  qualityReference: z.string().min(1),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const ApproveFormulaHistory = ({ formulaHistoryId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: formulaHistory } = useFormulaHistoryQuery(formulaHistoryId);
  const { mutateAsync: approve } =
    useApproveFormulaHistoryMutation(formulaHistoryId);

  const defaultValues = {
    qualityReference: formulaHistory?.qualityReference ?? "",
  };
  const methods = useForm<FormType>({
    defaultValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(
    () => methods.reset(defaultValues),
    [formulaHistory?.qualityReference],
  );
  const label = formulaHistory?.approved === null ? "Approve" : "Disapprove";

  const onSubmit = async (data: ValidatedType) => {
    try {
      await approve({
        approved: formulaHistory!.approved === null,
        qualityReference: data.qualityReference,
      });
      setLocation(`/formulas/${formulaHistory!.formulaId}/`);
      addToast(`Formula ${label}d`, "success");
    } catch {
      addToast("Try Again", "error");
    }
  };

  return (
    <>
      <Title title="Approve formula" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <TextField
                autoComplete="off"
                fullWidth
                label="Quality Reference"
                name="qualityReference"
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label={label} />
              <FormLinks
                links={[
                  {
                    label: "Back",
                    to: `/formulas/${formulaHistory?.formulaId}/`,
                  },
                ]}
              />
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    </>
  );
};

export default ApproveFormulaHistory;
