import {
  formatDateTime,
  ToastContext,
} from "@curaleaf-international/components";
import LoadingButton from "@mui/lab/LoadingButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useContext } from "react";
import { UAParser } from "ua-parser-js";

import { StaffSession } from "src/models";
import { useInvalidateStaffSessionMutation } from "src/queries";

interface IProps {
  session: StaffSession;
}

const SessionRow = ({ session }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: invalidateSession, isPending } =
    useInvalidateStaffSessionMutation(session.id);

  const parser = new UAParser(session.userAgent);

  const deleteSession = async () => {
    try {
      await invalidateSession();
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <TableRow>
      <TableCell>{session.id}</TableCell>
      <TableCell>{formatDateTime(session.start)}</TableCell>
      <TableCell>{formatDateTime(session.stop)}</TableCell>
      <TableCell>{session.remoteAddr}</TableCell>
      <TableCell>
        {parser.getBrowser().name} ({parser.getBrowser().version}) on{" "}
        {parser.getOS().name} ({parser.getOS().version})
      </TableCell>
      <TableCell>
        {session.valid ? (
          <LoadingButton
            loading={isPending}
            onClick={deleteSession}
            size="small"
            variant="outlined"
          >
            Logout
          </LoadingButton>
        ) : (
          "Invalid"
        )}
      </TableCell>
    </TableRow>
  );
};

export default SessionRow;
