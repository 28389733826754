import { DashboardTile } from "@curaleaf-international/components";

import { useFormulasQuery } from "src/queries";

const Dashboard = () => {
  const { data: formulas } = useFormulasQuery();

  const unapproved = formulas?.filter(
    (formula) => formula.approved === null,
  ).length;

  return (
    <DashboardTile
      tileDescription="Unapproved formula"
      tileValue={unapproved?.toString() ?? ""}
    />
  );
};

export default Dashboard;
