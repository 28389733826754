import axios from "axios";

import { useMutation } from "src/query";

export const useSSOLinkMutation = () => {
  return useMutation({
    mutationFn: async () => {
      const response = await axios.post("/v1/utils/sso-url/");
      return response.data.url;
    },
  });
};
