import { z } from "zod";

const staffSessionSchema = z.object({
  id: z.number(),
  remoteAddr: z.string(),
  staffId: z.number(),
  start: z.coerce.date(),
  stop: z.coerce.date(),
  userAgent: z.string(),
  valid: z.boolean(),
});

export type StaffSession = z.infer<typeof staffSessionSchema>;

export const newStaffSession = (data: unknown): StaffSession =>
  staffSessionSchema.parse(data);
