import {
  QueryKey,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios from "axios";
import { Decimal } from "decimal.js";

import {
  Brand,
  Form,
  Formula,
  Species,
  newFormula,
  FlowerGrade,
  GmpGacp,
} from "src/models";
import { useMutation, useQuery } from "src/query";

export const useFormulasQuery = () =>
  useQuery<Formula[]>({
    queryKey: ["formulas"],
    queryFn: async ({ signal }) => {
      const response = await axios.get("/v1/formulas/", { signal });
      return response.data.formulas.map((data: unknown) => newFormula(data));
    },
  });

export const useFormulaQuery = (
  id: string | undefined,
  options?: Omit<
    UseQueryOptions<any, any, Formula, QueryKey>,
    "queryKey" | "queryFn"
  >,
) => {
  const queryClient = useQueryClient();
  return useQuery<Formula>({
    ...options,
    queryKey: ["formulas", id?.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/formulas/${id}/`, { signal });
      return newFormula(response.data);
    },
    enabled: id !== undefined,
    initialData: () =>
      id !== undefined
        ? queryClient
            .getQueryData<Formula[]>(["formulas"])
            ?.filter((formula) => formula.id === id)?.[0]
        : undefined,
  });
};

interface IFormulaData {
  appearance: string | null;
  aroma: string | null;
  brand: Brand;
  cbd: Decimal | null;
  cbg: Decimal | null;
  cbn: Decimal | null;
  controlled: boolean;
  cultivator: string | null;
  flowerGrade: FlowerGrade | null;
  form: Form;
  gmpGacp: GmpGacp | null;
  ingredients: string[];
  intendedUse: string | null;
  irradiationType: string | null;
  manufacturer: string | null;
  market: string;
  nameInternal: string;
  parentStrain: string | null;
  registeredName: string | null;
  sku: string | null;
  species: Species | null;
  strain: string;
  terpenes: string[];
  thc: Decimal | null;
}

export const useCreateFormulaMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IFormulaData) => {
      await axios.post("/v1/formulas/", data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["formulas"] });
    },
  });
};

export const useEditFormulaMutation = (formulaId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IFormulaData) =>
      await axios.put(`/v1/formulas/${formulaId}/`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["formulas"] });
    },
  });
};
