import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { Link as WLink } from "wouter";

import Logo from "src/components/Logo";

const sxToolbar = {
  paddingLeft: "env(safe-area-inset-left)",
  paddingRight: "env(safe-area-inset-right)",
  paddingTop: "env(safe-area-inset-top)",
};

interface IProps {
  toggleDrawer: () => void;
}

const TopBar = ({ toggleDrawer }: IProps) => (
  <AppBar
    enableColorOnDark
    position="fixed"
    sx={{
      backgroundColor: "brand.main",
      color: "brand.contrastText",
      zIndex: (theme) => theme.zIndex.drawer + 1,
    }}
  >
    <Toolbar sx={sxToolbar}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer}
        sx={{ mr: 2, display: { md: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      <Button color="inherit" component={WLink} to="/">
        <Logo height={40} />
      </Button>
    </Toolbar>
  </AppBar>
);

export default TopBar;
