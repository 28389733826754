import { z } from "zod";

export enum ActivityType {
  STAFF_CREATED = "STAFF_CREATED",
  STAFF_DEACTIVATED = "STAFF_DEACTIVATED",
  STAFF_DISABLED = "STAFF_DISABLED",
  STAFF_ENABLED = "STAFF_ENABLED",
  STAFF_UPDATED = "STAFF_UPDATED",
  SYSTEM_CREATED = "SYSTEM_CREATED",
  SYSTEM_UPDATED = "SYSTEM_UPDATED",
  UAL_UPLOADED = "UAL_UPLOADED",
  UAR_ADJUSTED = "UAR_ADJUSTED",
  UAR_CLOSED = "UAR_CLOSED",
  UAR_CREATED = "UAR_CREATED",
}

const activitySchema = z.object({
  activity: z.nativeEnum(ActivityType),
  data: z.any(),
  staffId: z.number().nullable(),
  staffMemberEmail: z.string().nullable(),
  timestamp: z.coerce.date(),
});

export type Activity = z.infer<typeof activitySchema>;

export const newActivity = (data: unknown): Activity =>
  activitySchema.parse(data);
