import { createTheme } from "@curaleaf-international/components";
import type {} from "@mui/lab/themeAugmentation";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useMemo } from "react";

interface IProps {
  children: React.ReactNode;
}

const ThemeProvider = ({ children }: IProps) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = useMemo(() => {
    return createTheme({
      colorPreset: import.meta.env.DEV ? "clinic-dev" : "clinic",
      contrast: "high",
      fonts: {
        base: "Gothic A1",
        heading: "Playfair Display",
      },
      paletteMode: prefersDarkMode ? "dark" : "light",
    });
  }, [prefersDarkMode]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
