import { formatDate, formatDateTime } from "@curaleaf-international/components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MouseEvent, useState } from "react";
import { Link as WLink } from "wouter";

import { Staff } from "src/models";

interface IProps {
  staff: Staff;
}

const StaffRow = ({ staff }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          component={WLink}
          onClick={handleClose}
          to={`/staff/${staff.id}/edit/roles/`}
        >
          Edit
        </MenuItem>
        <MenuItem
          component={WLink}
          onClick={handleClose}
          to={`/staff/${staff.id}/disabled/`}
        >
          {staff.disabled === null ? "Disable" : "Enable"}
        </MenuItem>
      </Menu>
      <TableRow>
        <TableCell>
          <Link component={WLink} to={`/staff/${staff.id}/`}>
            {staff.email}
          </Link>
        </TableCell>
        <TableCell>{formatDate(staff.created)}</TableCell>
        <TableCell>{staff.roles.join(", ")}</TableCell>
        <TableCell>{staff.activeSessions}</TableCell>
        <TableCell>
          {staff.lastLogin !== null ? formatDateTime(staff.lastLogin) : ""}
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default StaffRow;
