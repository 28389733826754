import { zodDecimal } from "@curaleaf-international/components";
import { z } from "zod";

export enum Brand {
  CURALEAF = "CURALEAF",
  FOUR_20 = "FOUR_20",
  FIND = "FIND",
  GRASSROOTS = "GRASSROOTS",
  HUALA = "HUALA",
}

export enum Form {
  CAPSULE = "CAPSULE",
  FLOWER = "FLOWER",
  OIL = "OIL",
  OINTMENT = "OINTMENT",
  PASTILLE = "PASTILLE",
  VAPE_CARTRIDGE = "VAPE_CARTRIDGE",
}

export enum Species {
  HYBRID = "HYBRID",
  HYBRID_INDICA = "HYBRID_INDICA",
  HYBRID_SATIVA = "HYBRID_SATIVA",
  INDICA = "INDICA",
  SATIVA = "SATIVA",
}

export enum FlowerGrade {
  STANDARD = "STANDARD",
  SMALL_BUD = "SMALL_BUD",
}

export enum GmpGacp {
  GMP = "GMP",
  GACP = "GACP",
}

const formulaSchema = z.object({
  id: z.string().uuid(),
  appearance: z.string().nullable(),
  approved: z.coerce.date().nullable(),
  approverEmail: z.string().nullable(),
  approverId: z.number().nullable(),
  aroma: z.string().nullable(),
  brand: z.nativeEnum(Brand),
  cbd: zodDecimal().nullable(),
  cbg: zodDecimal().nullable(),
  cbn: zodDecimal().nullable(),
  controlled: z.boolean(),
  cultivator: z.string().nullable(),
  flowerGrade: z.nativeEnum(FlowerGrade).nullable(),
  form: z.nativeEnum(Form),
  gmpGacp: z.nativeEnum(GmpGacp).nullable(),
  historyId: z.string().uuid(),
  ingredients: z.array(z.string()),
  intendedUse: z.string().nullable(),
  irradiationType: z.string().nullable(),
  manufacturer: z.string().nullable(),
  market: z.string(),
  nameInternal: z.string(),
  parentStrain: z.string().nullable(),
  qualityReference: z.string().nullable(),
  registeredName: z.string().nullable(),
  sku: z.string().nullable(),
  species: z.nativeEnum(Species).nullable(),
  staffEmail: z.string().nullable(),
  staffId: z.number().nullable(),
  strain: z.string(),
  terpenes: z.array(z.string()),
  thc: zodDecimal().nullable(),
});

export type Formula = z.infer<typeof formulaSchema>;

export const newFormula = (data: unknown): Formula => formulaSchema.parse(data);
