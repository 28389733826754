interface IProps {
  color?: string;
  height: number;
}

const Logo = ({ height, color = "currentColor" }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    viewBox="0 0 140 70.176"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_93"
          data-name="Rectangle 93"
          width="140"
          height="70.176"
          fill="none"
        ></rect>
      </clipPath>
    </defs>
    <g id="Group_158" data-name="Group 158" transform="translate(0 0)">
      <g
        id="Group_157"
        data-name="Group 157"
        transform="translate(0 0)"
        clipPath="url(#clip-path)"
      >
        <path
          id="Path_344"
          data-name="Path 344"
          d="M106.86,37.9c-.191-4.219-2.751-7.334-7.35-7.334A7.9,7.9,0,0,0,91.731,37.9ZM91.683,40.484a8.14,8.14,0,0,0,8.109,8.15,8.323,8.323,0,0,0,6.4-2.925,1.609,1.609,0,0,1,1.233-.623,1.413,1.413,0,0,1,1.469,1.354c0,.029,0,.057,0,.085a1.674,1.674,0,0,1-.474,1.15,11.208,11.208,0,0,1-8.631,3.74c-6.592,0-11.476-5.034-11.476-11.789S93.2,27.834,99.507,27.834c6.4,0,10.619,4.363,10.619,10.979,0,1.1-.57,1.678-1.755,1.678Z"
          transform="translate(-8.839 -2.786)"
          fill={color}
        ></path>
        <path
          id="Path_345"
          data-name="Path 345"
          d="M87.338,47.807a1.443,1.443,0,0,1-1.4-.8,3.807,3.807,0,0,1-.309-1.654V20.526A1.4,1.4,0,0,0,85.2,19.5a1.772,1.772,0,0,0-2.309,0,1.4,1.4,0,0,0-.43,1.024V45.577a6.253,6.253,0,0,0,.935,3.8,3.357,3.357,0,0,0,2.861,1.159,2.029,2.029,0,0,0,1.431-.411A1.485,1.485,0,0,0,88.109,49a1.817,1.817,0,0,0-.221-.911.544.544,0,0,0-.55-.279"
          transform="translate(-8.251 -1.909)"
          fill={color}
        ></path>
        <path
          id="Path_346"
          data-name="Path 346"
          d="M153.985,19.876a1.554,1.554,0,0,0-.928-.581,4.273,4.273,0,0,0-.8-.138,14.019,14.019,0,0,0-1.468-.067,7.307,7.307,0,0,0-5.945,2.5q-2.242,2.5-2.25,7.131V49.055a1.4,1.4,0,0,0,.434,1.058,1.726,1.726,0,0,0,2.268.005,1.439,1.439,0,0,0,.417-1.064V30.883h4.969a1.318,1.318,0,0,0,.99-.4,1.41,1.41,0,0,0,.4-1.042,1.284,1.284,0,0,0-.388-.969,1.368,1.368,0,0,0-.99-.383h-4.95l.027-.425a6.939,6.939,0,0,1,1.375-4.15,4.26,4.26,0,0,1,3.7-1.54,8.161,8.161,0,0,1,1.213.072,5.931,5.931,0,0,0,.875.062,1.446,1.446,0,0,0,.969-.333,1.327,1.327,0,0,0,.369-1.063,1.526,1.526,0,0,0-.27-.836"
          transform="translate(-14.272 -1.909)"
          fill={color}
        ></path>
        <path
          id="Path_347"
          data-name="Path 347"
          d="M18.446,45.949a1.366,1.366,0,0,1,1.422,1.308c0,.028,0,.055,0,.083a1.71,1.71,0,0,1-.57,1.246,10.181,10.181,0,0,1-7.444,2.828C4.694,51.415,0,46.381,0,39.626S4.694,27.837,11.855,27.837a10.73,10.73,0,0,1,7.16,2.684,1.754,1.754,0,0,1,.617,1.294,1.439,1.439,0,0,1-1.44,1.44h-.03c-.427,0-.759-.1-1.613-.91A6.725,6.725,0,0,0,11.9,30.571c-5.168,0-8.488,3.932-8.488,9.059,0,5.082,3.271,9.013,8.44,9.013a7.253,7.253,0,0,0,5.309-2.062,1.852,1.852,0,0,1,1.281-.623"
          transform="translate(0 -2.786)"
          fill={color}
        ></path>
        <path
          id="Path_348"
          data-name="Path 348"
          d="M40.306,27.831a1.709,1.709,0,0,0-1.116.39,1.408,1.408,0,0,0-.513,1.122V42.358a7.734,7.734,0,0,1-.38,2.459,5.163,5.163,0,0,1-1.114,1.9,5.444,5.444,0,0,1-1.8,1.26,6.708,6.708,0,0,1-4.895,0,5.431,5.431,0,0,1-1.805-1.26,5.152,5.152,0,0,1-1.114-1.9,7.743,7.743,0,0,1-.38-2.46V29.343a1.387,1.387,0,0,0-.514-1.122,1.709,1.709,0,0,0-1.116-.39,1.535,1.535,0,0,0-1.092.409,1.447,1.447,0,0,0-.45,1.1V42.268a11.229,11.229,0,0,0,.56,3.619,8.141,8.141,0,0,0,1.7,2.907,7.752,7.752,0,0,0,2.808,1.934,10.062,10.062,0,0,0,3.848.688,9.947,9.947,0,0,0,3.8-.688,7.745,7.745,0,0,0,2.81-1.936,8.406,8.406,0,0,0,1.72-2.9,10.87,10.87,0,0,0,.584-3.621V29.343a1.449,1.449,0,0,0-.45-1.1,1.544,1.544,0,0,0-1.092-.408"
          transform="translate(-2.404 -2.785)"
          fill={color}
        ></path>
        <path
          id="Path_349"
          data-name="Path 349"
          d="M65.055,48.174c-4.786,0-7.786-3.838-7.786-8.574s3.2-8.574,7.786-8.574c4.148,0,7.787,3.621,7.787,8.574,0,5.258-3.639,8.574-7.787,8.574m13.209.794a.539.539,0,0,0-.548-.278,1.439,1.439,0,0,1-1.4-.795,3.79,3.79,0,0,1-.309-1.65V29.279a1.4,1.4,0,0,0-.428-1.022,1.77,1.77,0,0,0-2.3,0,1.4,1.4,0,0,0-.428,1.022v3.05a10.99,10.99,0,0,0-2.631-2.864,8.8,8.8,0,0,0-5.422-1.635,9.95,9.95,0,0,0-4.23.9A10.6,10.6,0,0,0,57.185,31.2a11.446,11.446,0,0,0-2.254,3.75,13.333,13.333,0,0,0-.816,4.692,13.486,13.486,0,0,0,.793,4.669,11.208,11.208,0,0,0,2.234,3.729A10.661,10.661,0,0,0,60.5,50.506a9.829,9.829,0,0,0,4.209.9,8.812,8.812,0,0,0,5.4-1.7,11.352,11.352,0,0,0,2.747-2.938V46.6a6.179,6.179,0,0,0,.929,3.652,3.352,3.352,0,0,0,2.855,1.156A2.02,2.02,0,0,0,78.062,51a1.478,1.478,0,0,0,.423-1.125,1.813,1.813,0,0,0-.22-.91"
          transform="translate(-5.416 -2.785)"
          fill={color}
        ></path>
        <path
          id="Path_350"
          data-name="Path 350"
          d="M64.922,24.266a1.47,1.47,0,0,0,1.184-.472,1.651,1.651,0,0,0,.376-1.105c0-.433-.316-1.761-2.43-1.761a15.745,15.745,0,0,0-9.32,2.866c-3.433,2.3-4.566,6.045-4.867,6.5V28.624a1.388,1.388,0,0,0-1.274-1.493,1.44,1.44,0,0,0-.285.007,1.539,1.539,0,0,0-1.14.424,1.455,1.455,0,0,0-.418,1.061V49.191a1.432,1.432,0,0,0,.487,1.129,1.644,1.644,0,0,0,1.119.4,1.556,1.556,0,0,0,1.105-.412,1.468,1.468,0,0,0,.45-1.119V41.832a30.545,30.545,0,0,1,.27-5.826,17.333,17.333,0,0,1,2.02-5.417,12.318,12.318,0,0,1,3.579-3.982c.27-.186.545-.36.831-.52a16.921,16.921,0,0,1,8.308-1.82"
          transform="translate(-4.679 -2.094)"
          fill={color}
        ></path>
        <path
          id="Path_351"
          data-name="Path 351"
          d="M124.608,48.174c-4.786,0-7.786-3.838-7.786-8.574s3.2-8.574,7.786-8.574c4.148,0,7.787,3.621,7.787,8.574,0,5.258-3.639,8.574-7.787,8.574m13.209.794a.539.539,0,0,0-.548-.278,1.44,1.44,0,0,1-1.4-.795,3.816,3.816,0,0,1-.308-1.65V29.279a1.4,1.4,0,0,0-.428-1.022,1.77,1.77,0,0,0-2.3,0,1.4,1.4,0,0,0-.428,1.022v3.05a11.016,11.016,0,0,0-2.63-2.864,8.8,8.8,0,0,0-5.423-1.635,9.95,9.95,0,0,0-4.23.9,10.611,10.611,0,0,0-3.383,2.468,11.459,11.459,0,0,0-2.25,3.75,13.333,13.333,0,0,0-.816,4.692,13.485,13.485,0,0,0,.793,4.669,11.208,11.208,0,0,0,2.234,3.729,10.661,10.661,0,0,0,3.353,2.468,9.829,9.829,0,0,0,4.209.9,8.812,8.812,0,0,0,5.4-1.7A11.347,11.347,0,0,0,132.4,46.76V46.6a6.179,6.179,0,0,0,.929,3.652,3.351,3.351,0,0,0,2.855,1.156,2.02,2.02,0,0,0,1.427-.41,1.478,1.478,0,0,0,.423-1.125,1.813,1.813,0,0,0-.22-.91"
          transform="translate(-11.377 -2.785)"
          fill={color}
        ></path>
        <path
          id="Path_352"
          data-name="Path 352"
          d="M89.147.049c-.3-.288-2.525.8-4.842,1.047s-5.412.4-8.662,3.619c-4.332,4.3-2.109,10.57-1.936,10.123s-1.253-4.822,2.758-8.857,9.976-3.452,9.976-3.452-.45,6.79-3.847,10.092a9.425,9.425,0,0,1-6.018,2.936l.157-.753a15.011,15.011,0,0,1,.434-1.959A6.92,6.92,0,0,1,78,11.124a11.141,11.141,0,0,1,1.223-1.511c.111-.118.22-.244.339-.355.024-.025.279-.256.2-.3a.3.3,0,0,0-.208.018,2.168,2.168,0,0,0-.408.18,4.668,4.668,0,0,0-.563.36,6.923,6.923,0,0,0-.673.573,8.616,8.616,0,0,0-.738.81,10.9,10.9,0,0,0-.757,1.075,13.106,13.106,0,0,0-.732,1.367c-.314.679-.54,1.39-.839,2.07a14.226,14.226,0,0,1-1.08,1.987,2.078,2.078,0,0,1-1.342,1.016,3.4,3.4,0,0,1-.674.085,2.56,2.56,0,0,0-1.295.373A1.682,1.682,0,0,0,70,21.088c.441.7,1.62.711,2.319.469a4.895,4.895,0,0,0,1.969-1.7l.031-.035.069-.09.106-.146.136-.187.159-.219.174-.238.18-.25.18-.253c.06-.081.119-.163.18-.244l.165-.225.144-.2.115-.159.062-.085a.416.416,0,0,1,.06-.077.352.352,0,0,1,.2-.071,1.709,1.709,0,0,1,.18-.01l.071-.005c.026,0,.055-.008.082-.008a14.677,14.677,0,0,0,2.368-.509,11.6,11.6,0,0,0,3.2-1.485,7.616,7.616,0,0,0,1.062-.876c3.914-3.885,4.329-8.447,4.878-10.6S89.446.341,89.146.052"
          transform="translate(-6.983 0)"
          fill={color}
        ></path>
        <path
          id="Path_353"
          data-name="Path 353"
          d="M2.679,62.345v-.559A.786.786,0,0,1,3.466,61h.373a.785.785,0,0,1,.786.787v.559a.785.785,0,0,1-.786.787H3.466a.786.786,0,0,1-.787-.787m.083,3.642a.786.786,0,0,1,.787-.787h.186a.787.787,0,0,1,.787.787v9.147a.787.787,0,0,1-.787.787H3.548a.787.787,0,0,1-.787-.787Z"
          transform="translate(-0.268 -6.105)"
          fill={color}
        ></path>
        <path
          id="Path_354"
          data-name="Path 354"
          d="M2.679,62.345v-.559A.786.786,0,0,1,3.466,61h.373a.785.785,0,0,1,.786.787v.559a.785.785,0,0,1-.786.787H3.466A.786.786,0,0,1,2.679,62.345Zm.083,3.642a.786.786,0,0,1,.787-.787h.186a.787.787,0,0,1,.787.787v9.147a.787.787,0,0,1-.787.787H3.548a.787.787,0,0,1-.787-.787Z"
          transform="translate(-0.268 -6.105)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="0.25"
        ></path>
        <path
          id="Path_355"
          data-name="Path 355"
          d="M8.037,66.427a.7.7,0,0,1,.683-.787h.31a.7.7,0,0,1,.682.787v.7a4.976,4.976,0,0,1,3.643-1.739c1.966,0,3.022.994,3.022,3.663v6.519a.787.787,0,0,1-.787.787H15.4a.787.787,0,0,1-.787-.787v-6.54c0-1.448-.537-2.09-1.779-2.09-.952,0-1.821.393-3.042,1.656v6.973a.787.787,0,0,1-.787.787H8.824a.787.787,0,0,1-.787-.787Z"
          transform="translate(-0.804 -6.545)"
          fill={color}
        ></path>
        <path
          id="Path_356"
          data-name="Path 356"
          d="M8.037,66.427a.7.7,0,0,1,.683-.787h.31a.7.7,0,0,1,.682.787v.7a4.976,4.976,0,0,1,3.643-1.739c1.966,0,3.022.994,3.022,3.663v6.519a.787.787,0,0,1-.787.787H15.4a.787.787,0,0,1-.787-.787v-6.54c0-1.448-.537-2.09-1.779-2.09-.952,0-1.821.393-3.042,1.656v6.973a.787.787,0,0,1-.787.787H8.824a.787.787,0,0,1-.787-.787Z"
          transform="translate(-0.804 -6.545)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="0.25"
        ></path>
        <path
          id="Path_357"
          data-name="Path 357"
          d="M20.764,66.723h-.889c-.394,0-.7-.207-.7-.6v-.248c0-.393.31-.6.7-.6h.889V62.523a.786.786,0,0,1,.787-.787h.166a.786.786,0,0,1,.787.787v2.752h1.614c.392,0,.7.207.7.6v.248c0,.393-.31.6-.7.6H22.5V73.3c0,.911.331,1.325,1.118,1.325.228,0,.455-.041.683-.041.289,0,.517.062.517.331v.455c0,.476-.207.787-1.635.787-1.758,0-2.421-.683-2.421-2.774Z"
          transform="translate(-1.919 -6.179)"
          fill={color}
        ></path>
        <path
          id="Path_358"
          data-name="Path 358"
          d="M20.764,66.723h-.889c-.394,0-.7-.207-.7-.6v-.248c0-.393.31-.6.7-.6h.889V62.523a.786.786,0,0,1,.787-.787h.166a.786.786,0,0,1,.787.787v2.752h1.614c.392,0,.7.207.7.6v.248c0,.393-.31.6-.7.6H22.5V73.3c0,.911.331,1.325,1.118,1.325.228,0,.455-.041.683-.041.289,0,.517.062.517.331v.455c0,.476-.207.787-1.635.787-1.758,0-2.421-.683-2.421-2.774Z"
          transform="translate(-1.919 -6.179)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="0.25"
        ></path>
        <path
          id="Path_359"
          data-name="Path 359"
          d="M26.92,71c0-3.436,1.884-5.608,4.554-5.608,2.9,0,4.428,2.07,4.428,5.215,0,.186-.186.642-.559.642H28.659c.1,2.379.911,3.849,2.918,3.849a2.926,2.926,0,0,0,2.546-1.263c.247-.331.5-.58.911-.352l.31.166c.31.166.414.476.123.931a4.509,4.509,0,0,1-4.014,2.028c-2.876,0-4.532-2.172-4.532-5.607m7.243-1.056c-.021-1.884-.993-3.124-2.69-3.124s-2.567,1.407-2.774,3.124Z"
          transform="translate(-2.694 -6.545)"
          fill={color}
        ></path>
        <path
          id="Path_360"
          data-name="Path 360"
          d="M26.92,71c0-3.436,1.884-5.608,4.554-5.608,2.9,0,4.428,2.07,4.428,5.215,0,.186-.186.642-.559.642H28.659c.1,2.379.911,3.849,2.918,3.849a2.926,2.926,0,0,0,2.546-1.263c.247-.331.5-.58.911-.352l.31.166c.31.166.414.476.123.931a4.509,4.509,0,0,1-4.014,2.028C28.576,76.608,26.92,74.435,26.92,71Zm7.243-1.056c-.021-1.884-.993-3.124-2.69-3.124s-2.567,1.407-2.774,3.124Z"
          transform="translate(-2.694 -6.545)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="0.25"
        ></path>
        <path
          id="Path_361"
          data-name="Path 361"
          d="M39.433,66.427a.786.786,0,0,1,.787-.787h.1a.785.785,0,0,1,.786.787v1.242c1.1-1.966,2.463-2.277,3-2.277a.553.553,0,0,1,.6.58v.58a.527.527,0,0,1-.517.58c-1.138,0-2.317.931-3,2.586v5.857a.787.787,0,0,1-.787.787H40.22a.787.787,0,0,1-.787-.787Z"
          transform="translate(-3.947 -6.545)"
          fill={color}
        ></path>
        <path
          id="Path_362"
          data-name="Path 362"
          d="M39.433,66.427a.786.786,0,0,1,.787-.787h.1a.785.785,0,0,1,.786.787v1.242c1.1-1.966,2.463-2.277,3-2.277a.553.553,0,0,1,.6.58v.58a.527.527,0,0,1-.517.58c-1.138,0-2.317.931-3,2.586v5.857a.787.787,0,0,1-.787.787H40.22a.787.787,0,0,1-.787-.787Z"
          transform="translate(-3.947 -6.545)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="0.25"
        ></path>
        <path
          id="Path_363"
          data-name="Path 363"
          d="M47.16,66.427a.7.7,0,0,1,.683-.787h.31a.7.7,0,0,1,.682.787v.7a4.976,4.976,0,0,1,3.643-1.739c1.966,0,3.022.994,3.022,3.663v6.519a.787.787,0,0,1-.787.787h-.186a.787.787,0,0,1-.787-.787v-6.54c0-1.448-.537-2.09-1.779-2.09-.952,0-1.821.393-3.042,1.656v6.973a.787.787,0,0,1-.787.787h-.186a.787.787,0,0,1-.787-.787Z"
          transform="translate(-4.72 -6.545)"
          fill={color}
        ></path>
        <path
          id="Path_364"
          data-name="Path 364"
          d="M47.16,66.427a.7.7,0,0,1,.683-.787h.31a.7.7,0,0,1,.682.787v.7a4.976,4.976,0,0,1,3.643-1.739c1.966,0,3.022.994,3.022,3.663v6.519a.787.787,0,0,1-.787.787h-.186a.787.787,0,0,1-.787-.787v-6.54c0-1.448-.537-2.09-1.779-2.09-.952,0-1.821.393-3.042,1.656v6.973a.787.787,0,0,1-.787.787h-.186a.787.787,0,0,1-.787-.787Z"
          transform="translate(-4.72 -6.545)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="0.25"
        ></path>
        <path
          id="Path_365"
          data-name="Path 365"
          d="M60.509,70.752a20.138,20.138,0,0,1,4.9-1.428,3.987,3.987,0,0,0,.021-.5c0-1.489-.724-2.007-2.152-2.007a2.916,2.916,0,0,0-2.38,1.034.758.758,0,0,1-.993.228L59.765,68a.658.658,0,0,1-.166-.972,4.493,4.493,0,0,1,3.745-1.635c2.629,0,3.808,1.138,3.808,3.5v5.795l.082.889c.063.56-.1.787-.559.787H66.22c-.454,0-.682-.331-.724-.765l-.062-.517a5.76,5.76,0,0,1-3.725,1.51,2.713,2.713,0,0,1-2.981-2.876,3.171,3.171,0,0,1,1.78-2.959m1.677,4.408a4.387,4.387,0,0,0,3.187-1.532V70.607a13.765,13.765,0,0,0-3.911,1.159,1.947,1.947,0,0,0-.952,1.779,1.508,1.508,0,0,0,1.677,1.614"
          transform="translate(-5.878 -6.545)"
          fill={color}
        ></path>
        <path
          id="Path_366"
          data-name="Path 366"
          d="M60.509,70.752a20.138,20.138,0,0,1,4.9-1.428,3.987,3.987,0,0,0,.021-.5c0-1.489-.724-2.007-2.152-2.007a2.916,2.916,0,0,0-2.38,1.034.758.758,0,0,1-.993.228L59.765,68a.658.658,0,0,1-.166-.972,4.493,4.493,0,0,1,3.745-1.635c2.629,0,3.808,1.138,3.808,3.5v5.795l.082.889c.063.56-.1.787-.559.787H66.22c-.454,0-.682-.331-.724-.765l-.062-.517a5.76,5.76,0,0,1-3.725,1.51,2.713,2.713,0,0,1-2.981-2.876A3.171,3.171,0,0,1,60.509,70.752Zm1.677,4.408a4.387,4.387,0,0,0,3.187-1.532V70.607a13.765,13.765,0,0,0-3.911,1.159,1.947,1.947,0,0,0-.952,1.779A1.508,1.508,0,0,0,62.186,75.16Z"
          transform="translate(-5.878 -6.545)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="0.25"
        ></path>
        <path
          id="Path_367"
          data-name="Path 367"
          d="M71.57,66.723h-.889c-.394,0-.7-.207-.7-.6v-.248c0-.393.31-.6.7-.6h.889V62.523a.786.786,0,0,1,.787-.787h.166a.786.786,0,0,1,.787.787v2.752h1.614c.392,0,.7.207.7.6v.248c0,.393-.31.6-.7.6H73.308V73.3c0,.911.331,1.325,1.118,1.325.228,0,.455-.041.683-.041.289,0,.517.062.517.331v.455c0,.476-.207.787-1.635.787-1.758,0-2.421-.683-2.421-2.774Z"
          transform="translate(-7.004 -6.179)"
          fill={color}
        ></path>
        <path
          id="Path_368"
          data-name="Path 368"
          d="M71.57,66.723h-.889c-.394,0-.7-.207-.7-.6v-.248c0-.393.31-.6.7-.6h.889V62.523a.786.786,0,0,1,.787-.787h.166a.786.786,0,0,1,.787.787v2.752h1.614c.392,0,.7.207.7.6v.248c0,.393-.31.6-.7.6H73.308V73.3c0,.911.331,1.325,1.118,1.325.228,0,.455-.041.683-.041.289,0,.517.062.517.331v.455c0,.476-.207.787-1.635.787-1.758,0-2.421-.683-2.421-2.774Z"
          transform="translate(-7.004 -6.179)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="0.25"
        ></path>
        <path
          id="Path_369"
          data-name="Path 369"
          d="M78.464,62.345v-.559A.786.786,0,0,1,79.251,61h.373a.785.785,0,0,1,.786.787v.559a.785.785,0,0,1-.786.787h-.373a.786.786,0,0,1-.787-.787m.083,3.642a.786.786,0,0,1,.787-.787h.186a.787.787,0,0,1,.787.787v9.147a.787.787,0,0,1-.787.787h-.186a.787.787,0,0,1-.787-.787Z"
          transform="translate(-7.853 -6.105)"
          fill={color}
        ></path>
        <path
          id="Path_370"
          data-name="Path 370"
          d="M78.464,62.345v-.559A.786.786,0,0,1,79.251,61h.373a.785.785,0,0,1,.786.787v.559a.785.785,0,0,1-.786.787h-.373A.786.786,0,0,1,78.464,62.345Zm.083,3.642a.786.786,0,0,1,.787-.787h.186a.787.787,0,0,1,.787.787v9.147a.787.787,0,0,1-.787.787h-.186a.787.787,0,0,1-.787-.787Z"
          transform="translate(-7.853 -6.105)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="0.25"
        ></path>
        <path
          id="Path_371"
          data-name="Path 371"
          d="M87.568,65.392c2.835,0,4.553,2.09,4.553,5.65,0,3.435-1.759,5.567-4.553,5.567s-4.574-2.132-4.574-5.567c0-3.559,1.739-5.65,4.574-5.65m2.793,5.65c0-2.462-.889-4.18-2.793-4.18s-2.794,1.718-2.794,4.18.912,4.1,2.794,4.1,2.793-1.635,2.793-4.1"
          transform="translate(-8.307 -6.545)"
          fill={color}
        ></path>
        <path
          id="Path_372"
          data-name="Path 372"
          d="M87.568,65.392c2.835,0,4.553,2.09,4.553,5.65,0,3.435-1.759,5.567-4.553,5.567s-4.574-2.132-4.574-5.567C82.994,67.482,84.733,65.392,87.568,65.392Zm2.793,5.65c0-2.462-.889-4.18-2.793-4.18s-2.794,1.718-2.794,4.18.912,4.1,2.794,4.1S90.362,73.5,90.362,71.042Z"
          transform="translate(-8.307 -6.545)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="0.25"
        ></path>
        <path
          id="Path_373"
          data-name="Path 373"
          d="M95.645,66.427a.7.7,0,0,1,.683-.787h.31a.7.7,0,0,1,.682.787v.7a4.976,4.976,0,0,1,3.643-1.739c1.966,0,3.022.994,3.022,3.663v6.519a.787.787,0,0,1-.787.787h-.186a.787.787,0,0,1-.787-.787v-6.54c0-1.448-.537-2.09-1.779-2.09-.952,0-1.821.393-3.042,1.656v6.973a.787.787,0,0,1-.787.787h-.186a.787.787,0,0,1-.787-.787Z"
          transform="translate(-9.573 -6.545)"
          fill={color}
        ></path>
        <path
          id="Path_374"
          data-name="Path 374"
          d="M95.645,66.427a.7.7,0,0,1,.683-.787h.31a.7.7,0,0,1,.682.787v.7a4.976,4.976,0,0,1,3.643-1.739c1.966,0,3.022.994,3.022,3.663v6.519a.787.787,0,0,1-.787.787h-.186a.787.787,0,0,1-.787-.787v-6.54c0-1.448-.537-2.09-1.779-2.09-.952,0-1.821.393-3.042,1.656v6.973a.787.787,0,0,1-.787.787h-.186a.787.787,0,0,1-.787-.787Z"
          transform="translate(-9.573 -6.545)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="0.25"
        ></path>
        <path
          id="Path_375"
          data-name="Path 375"
          d="M108.993,70.752a20.138,20.138,0,0,1,4.9-1.428,3.987,3.987,0,0,0,.021-.5c0-1.489-.724-2.007-2.152-2.007a2.916,2.916,0,0,0-2.38,1.034.759.759,0,0,1-.993.228L108.249,68a.658.658,0,0,1-.166-.972,4.493,4.493,0,0,1,3.745-1.635c2.629,0,3.808,1.138,3.808,3.5v5.795l.082.889c.063.56-.1.787-.559.787H114.7c-.454,0-.682-.331-.724-.765l-.062-.517a5.76,5.76,0,0,1-3.725,1.51,2.713,2.713,0,0,1-2.981-2.876,3.171,3.171,0,0,1,1.78-2.959m1.677,4.408a4.387,4.387,0,0,0,3.187-1.532V70.607a13.765,13.765,0,0,0-3.911,1.159,1.947,1.947,0,0,0-.952,1.779,1.508,1.508,0,0,0,1.677,1.614"
          transform="translate(-10.731 -6.545)"
          fill={color}
        ></path>
        <path
          id="Path_376"
          data-name="Path 376"
          d="M108.993,70.752a20.138,20.138,0,0,1,4.9-1.428,3.987,3.987,0,0,0,.021-.5c0-1.489-.724-2.007-2.152-2.007a2.916,2.916,0,0,0-2.38,1.034.759.759,0,0,1-.993.228L108.249,68a.658.658,0,0,1-.166-.972,4.493,4.493,0,0,1,3.745-1.635c2.629,0,3.808,1.138,3.808,3.5v5.795l.082.889c.063.56-.1.787-.559.787H114.7c-.454,0-.682-.331-.724-.765l-.062-.517a5.76,5.76,0,0,1-3.725,1.51,2.713,2.713,0,0,1-2.981-2.876A3.171,3.171,0,0,1,108.993,70.752Zm1.677,4.408a4.387,4.387,0,0,0,3.187-1.532V70.607a13.765,13.765,0,0,0-3.911,1.159,1.947,1.947,0,0,0-.952,1.779A1.508,1.508,0,0,0,110.67,75.16Z"
          transform="translate(-10.731 -6.545)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="0.25"
        ></path>
        <rect
          id="Rectangle_91"
          data-name="Rectangle 91"
          width="1.759"
          height="14.858"
          rx="0.874"
          transform="translate(107.784 54.957)"
          fill={color}
        ></rect>
        <rect
          id="Rectangle_92"
          data-name="Rectangle 92"
          width="1.759"
          height="14.858"
          rx="0.874"
          transform="translate(107.784 54.957)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="0.25"
        ></rect>
      </g>
    </g>
  </svg>
);

export default Logo;
