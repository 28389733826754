import { sortByKey, SkeletonRow } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Fuse from "fuse.js";
import { memo, useState } from "react";

import { Staff } from "src/models/staff";
import StaffRow from "src/pages/Staff/StaffRow";
import { useAllStaffQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties =
  | "activeSessions"
  | "created"
  | "email"
  | "lastLogin"
  | "id";
export type States = "disabled" | "enabled";

interface IProps {
  search: string;
  state?: States;
}

const StaffTable = ({ search, state }: IProps) => {
  const { data: staff } = useAllStaffQuery();
  const [order, setOrder] = useState<Direction>("desc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("created");

  const sortKey = (staff: Staff) => [staff[orderBy]];

  let rows;
  if (staff === undefined) {
    rows = <SkeletonRow cols={6} />;
  } else {
    let filteredStaff = Object.values(staff).filter(
      (member) => (state === "enabled") === (member.disabled === null),
    );

    if (search !== "") {
      const fuse = new Fuse(filteredStaff, {
        keys: ["email"],
      });
      filteredStaff = fuse.search(search).map((value) => value.item);
    } else {
      filteredStaff = filteredStaff.sort(sortByKey(sortKey, order));
    }

    rows = filteredStaff.map((staff) => (
      <StaffRow key={staff.id} staff={staff} />
    ));
  }

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "email"}
                direction={order}
                onClick={onSortClick("email")}
              >
                Email
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "created"}
                direction={order}
                onClick={onSortClick("created")}
              >
                Created
              </TableSortLabel>
            </TableCell>
            <TableCell>Roles</TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "activeSessions"}
                direction={order}
                onClick={onSortClick("activeSessions")}
              >
                Active sessions
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "lastLogin"}
                direction={order}
                onClick={onSortClick("lastLogin")}
              >
                Last login
              </TableSortLabel>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(StaffTable);
