import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { FormulaHistory, newFormulaHistory } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useFormulaHistoriesQuery = (formulaId: string | undefined) => {
  return useQuery<FormulaHistory[]>({
    queryKey: ["formulaHistory", "formula", formulaId?.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/formula-histories/formula/${formulaId}/`,
        {
          signal,
        },
      );
      return response.data.history.map((data: unknown) =>
        newFormulaHistory(data),
      );
    },
    enabled: formulaId !== undefined,
  });
};

export const useFormulaHistoryQuery = (
  formulaHistoryId: string | undefined,
) => {
  return useQuery<FormulaHistory>({
    queryKey: ["formulaHistory", formulaHistoryId?.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/formula-histories/${formulaHistoryId}/`,
        {
          signal,
        },
      );
      return newFormulaHistory(response.data);
    },
    enabled: formulaHistoryId !== undefined,
  });
};

interface IApprovalData {
  approved: boolean;
  qualityReference: string;
}

export const useApproveFormulaHistoryMutation = (formulaHistoryId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IApprovalData) =>
      await axios.put(
        `/v1/formula-histories/${formulaHistoryId}/approval/`,
        data,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["formulas"] });
      queryClient.invalidateQueries({ queryKey: ["formulaHistory"] });
    },
  });
};
