import { Title, ToastContext } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useContext } from "react";
import { useLocation } from "wouter";

import FormulaForm, { ValidatedType } from "src/components/FormulaForm";
import { Brand, Form, Species, FlowerGrade, GmpGacp } from "src/models";
import { useEditFormulaMutation, useFormulaQuery } from "src/queries";

interface IProps {
  formulaId: string;
}

const EditFormula = ({ formulaId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: formula } = useFormulaQuery(formulaId);
  const { mutateAsync: edit } = useEditFormulaMutation(formulaId);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        appearance:
          data.appearance.trim() !== "" ? data.appearance.trim() : null,
        aroma: data.aroma.trim() !== "" ? data.aroma.trim() : null,
        brand: data.brand as Brand,
        cbd: data.cbd !== null ? data.cbd.dividedBy(100) : null,
        cbg: data.cbg !== null ? data.cbg.dividedBy(100) : null,
        cbn: data.cbn !== null ? data.cbn.dividedBy(100) : null,
        controlled: data.controlled,
        cultivator:
          data.cultivator.trim() !== "" ? data.cultivator.trim() : null,
        flowerGrade:
          data.form === "FLOWER" ? (data.flowerGrade as FlowerGrade) : null,
        form: data.form as Form,
        gmpGacp: data.gmpGacp as GmpGacp,
        ingredients: data.ingredients,
        intendedUse:
          data.intendedUse.trim() !== "" ? data.intendedUse.trim() : null,
        irradiationType:
          data.irradiationType.trim() !== ""
            ? data.irradiationType.trim()
            : null,
        manufacturer:
          data.manufacturer.trim() !== "" ? data.manufacturer.trim() : null,
        market: data.market,
        nameInternal: data.nameInternal,
        parentStrain:
          data.parentStrain.trim() !== "" ? data.parentStrain.trim() : null,
        registeredName:
          data.registeredName.trim() !== "" ? data.registeredName.trim() : null,
        sku: data.sku.trim() !== "" ? data.sku.trim() : null,
        species: data.species === "" ? null : (data.species as Species),
        strain: data.strain,
        terpenes: data.terpenes,
        thc: data.thc !== null ? data.thc.dividedBy(100) : null,
      });
      addToast("Formula edited", "success");
      setLocation(`/formulas/${formulaId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: "/formulas/",
            label: "Formulas",
          },
          {
            to: `/formulas/${formulaId}/`,
            label: formula?.nameInternal ?? "",
          },
          { label: "Edit formula" },
        ]}
        title="Edit formula"
      />
      <Card>
        <FormulaForm
          defaultValues={{
            appearance: formula?.appearance ?? "",
            aroma: formula?.aroma ?? "",
            brand: formula?.brand ?? Brand.CURALEAF,
            cbd: formula?.cbd?.times(100) ?? null,
            cbg: formula?.cbg?.times(100) ?? null,
            cbn: formula?.cbn?.times(100) ?? null,
            controlled: formula?.controlled === true ? "true" : "false",
            cultivator: formula?.cultivator ?? "",
            flowerGrade: formula?.flowerGrade ?? FlowerGrade.STANDARD,
            form: formula?.form ?? Form.FLOWER,
            gmpGacp: formula?.gmpGacp ?? GmpGacp.GMP,
            ingredients: formula?.ingredients ?? [],
            intendedUse: formula?.intendedUse ?? "",
            irradiationType: formula?.irradiationType ?? "",
            manufacturer: formula?.manufacturer ?? "",
            market: formula?.market ?? "gbr",
            nameInternal: formula?.nameInternal ?? "",
            parentStrain: formula?.parentStrain ?? "",
            registeredName: formula?.registeredName ?? "",
            sku: formula?.sku ?? "",
            species: formula?.species ?? Species.INDICA,
            strain: formula?.strain ?? "",
            terpenes: formula?.terpenes ?? [],
            thc: formula?.thc?.times(100) ?? null,
          }}
          disabled={formula === undefined}
          label="Edit"
          onSubmit={onSubmit}
        />
      </Card>
    </>
  );
};

export default EditFormula;
