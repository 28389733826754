import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import { StaffRole } from "src/models";

interface IProps {
  roles: StaffRole[];
  children?: React.ReactNode;
}

const RequiresRoles = ({ roles, children }: IProps) => {
  const { member } = useContext(AuthContext);
  return member?.roles.some((role) => roles.includes(role)) ? children : null;
};

export default RequiresRoles;
