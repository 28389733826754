import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { StaffSession, newStaffSession } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useLoginMutation = () => {
  return useMutation({
    mutationFn: async (token: string) => {
      await axios.post("/v1/staff-sessions/", { token });
    },
  });
};

export const useStaffSessionsQuery = (staffId: number | undefined) =>
  useQuery<StaffSession[]>({
    queryKey: ["staffSessions", staffId],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/staff-sessions/staff/${staffId}/`, {
        signal,
      });
      return response.data.sessions.map((data: unknown) =>
        newStaffSession(data),
      );
    },
  });

export const useInvalidateStaffSessionMutation = (sessionId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () =>
      await axios.delete(`/v1/staff-sessions/${sessionId}/`),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["staffSessions"] }),
  });
};
