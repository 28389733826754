import {
  formatDateTime,
  Title,
  ToastContext,
  FormLinks,
} from "@curaleaf-international/components";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useContext } from "react";
import { useLocation } from "wouter";

import { useDisableStaffMutation, useStaffQuery } from "src/queries";

interface IProps {
  staffId: number;
}

const DisableStaff = ({ staffId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: staff } = useStaffQuery(staffId);
  const { mutateAsync: disableStaff, isPending } =
    useDisableStaffMutation(staffId);

  const onClick = async () => {
    try {
      await disableStaff({ disabled: staff!.disabled === null });
      addToast(
        `Staff ${staff!.disabled === null ? "disabled" : "enabled"}`,
        "success",
      );
      setLocation("/staff/");
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title title="Disable Staff Member" />
      <Card>
        <CardContent>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{staff?.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Disabled</TableCell>
                  <TableCell>
                    {staff?.disabled !== undefined && staff?.disabled !== null
                      ? formatDateTime(staff.disabled)
                      : "No"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            color="primary"
            disabled={staff === undefined}
            loading={isPending}
            onClick={onClick}
            variant="contained"
          >
            {staff?.disabled === null ? "Disable" : "Enable"}
          </LoadingButton>
          <FormLinks links={[{ label: "Back", to: `/staff/${staffId}/` }]} />
        </CardActions>
      </Card>
    </>
  );
};

export default DisableStaff;
