import { z } from "zod";

import { StaffRole } from "src/models/staff";

const authStaffSchema = z.object({
  id: z.number().int().positive(),
  email: z.string().email(),
  expires: z.coerce.date(),
  roles: z.array(z.nativeEnum(StaffRole)),
});

export type AuthStaff = z.infer<typeof authStaffSchema>;

export const newAuthStaff = (data: unknown): AuthStaff =>
  authStaffSchema.parse(data);
