import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { createElement as h, ReactElement, useContext } from "react";
import { DefaultParams, Redirect, RouteProps, useRoute } from "wouter";

import { AuthContext } from "src/AuthContext";

type SwitchedProps<T extends DefaultParams> = { match?: any } & RouteProps<T>;

const PrivateRoute = <T extends DefaultParams>({
  path,
  match,
  component,
  children,
}: SwitchedProps<T>): ReactElement | null => {
  const { member } = useContext(AuthContext);
  const useRouteMatch = useRoute(path as string);

  // `props.match` is present - Route is controlled by the Switch
  const [matches, params] = match || useRouteMatch;

  if (!matches) return null;

  if (member === undefined) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (member === null) {
    return <Redirect to="/login/" />;
  } else {
    // React-Router style `component` prop
    if (component) return h(component, { params });

    // support render prop or plain children
    return (
      typeof children === "function" ? children(params) : children
    ) as ReactElement;
  }
};

export default PrivateRoute;
