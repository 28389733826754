import { formatDate } from "@curaleaf-international/components";
import Alert from "@mui/material/Alert";
import { differenceInDays } from "date-fns";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";

const RoleExpiryBanner = () => {
  const { member } = useContext(AuthContext);

  if (member !== null && member !== undefined) {
    const diff = differenceInDays(member.expires, new Date());
    if (diff > 0 && diff < 14) {
      return (
        <Alert variant="filled" severity="info" square>
          Your roles will expire on {formatDate(member.expires)}, please ask
          your manager to renew them.
        </Alert>
      );
    }
  }
  return null;
};

export default RoleExpiryBanner;
