import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { useContext, useState } from "react";

import { AuthContext } from "src/AuthContext";
import LeftDrawer from "src/components/LeftDrawer";
import RoleExpiryBanner from "src/components/RoleExpiryBanner";
import TopBar from "src/components/TopBar";

interface IProps {
  children: React.ReactNode;
}

const PageWrapper = ({ children }: IProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { member } = useContext(AuthContext);

  return (
    <>
      <TopBar toggleDrawer={() => setDrawerOpen(!drawerOpen)} />
      <Box sx={{ display: { xs: "block", md: "flex" } }}>
        {member ? (
          <LeftDrawer
            closeDrawer={() => setDrawerOpen(false)}
            drawerOpen={drawerOpen}
          />
        ) : null}
        <Box sx={{ flexGrow: 1 }}>
          <Toolbar />
          <RoleExpiryBanner />
          <Container
            component="main"
            maxWidth="lg"
            sx={{ marginTop: 2, paddingBottom: 2 }}
          >
            {children}
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default PageWrapper;
