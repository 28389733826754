import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";

interface IProps {
  children: ReactNode;
  title: string;
}

const Page = ({ children, title }: IProps) => (
  <>
    <Helmet>
      <title>{title} - Curaleaf Clinic</title>
    </Helmet>
    <Toolbar sx={{ marginBottom: 2 }} />
    <Container component="main" maxWidth="md" sx={{ padding: 2 }}>
      <Typography component="h1" sx={{ marginBottom: 2 }} variant="h4">
        {title}
      </Typography>
      {children}
    </Container>
  </>
);

export default Page;
